<template>
    <div>
        <DxValidationGroup ref="formValidation">
            <div
                class="label-box"
                :style="isValidSdt ? '' : 'margin-bottom:48px'"
            >
                <DxTextBox
                    id="txtSoDienThoai"
                    v-model="model.soDienThoai"
                    mode="tel"
                    class="kichthuoclon"
                    height="84px"
                    styling-mode="outlined"
                    validationMessageMode="always"
                    :onFocusOut="onFocusOutSoDienThoai"
                    :onKeyUp="onKeyUpSoDienThoai"
                    :onInitialized="onInitializedSoDienThoai"
                    ><DxValidator ref="refValidateSoDienThoai">
                        <DxRequiredRule
                            message="Vui lòng nhập số điện thoại để tiếp tục mua vé!"
                        />
                        <DxStringLengthRule
                            :max="13"
                            message="Số điện thoại không được vượt quá 13 ký tự!"
                        />
                        <DxNumericRule message="Số điện thoại phải là số!" />
                        <DxPatternRule
                            :pattern="phonePattern"
                            message="Vui lòng nhập đúng định dạng số điện thoại để tiếp tục mua vé!"
                        />
                    </DxValidator>
                </DxTextBox>
                <div class="label">Điện thoại (*)</div>
            </div>
            <div class="font-italic pa pt-2" v-show="isValidSdt">
                Nhập nhanh số điện thoại bằng cách bỏ ẩn PC-Covid và hướng mã QR
                vào đầu đọc bên dưới
            </div>
            <div v-if="xeHetCho || xeKhongCoSoDoCho">
                <div class="label-box pb-2">
                    <DxNumberBox
                        id="nbbSoCho"
                        v-model="soChoPhu"
                        :show-spin-buttons="true"
                        styling-mode="outlined"
                        height="84px"
                        :min="1"
                        :max="500"
                        ><DxValidator ref="refValidateGiaVe">
                            <DxRequiredRule
                                message="Vui lòng số chỗ cần mua!" /></DxValidator
                    ></DxNumberBox>
                    <div class="label">Số chỗ (*)</div>
                </div>
                <DanhSachChipVue class="pa" :data="getDanhSachSoChoPhu" @ChipDangChon="chonGoiYSoCho"/>
            </div>
            <LuaChonDiemXuongVue ref="refLuaChonDiemXuong" />
            <div v-if="xeHetCho || xeKhongCoSoDoCho">
                <div class="label-box pa">
                    <DxNumberBox
                        v-model="giaVeDiemXuong"
                        :show-spin-buttons="false"
                        styling-mode="outlined"
                        validationMessageMode="always"
                        format="#0đ"
                        height="84px"
                        :min="0"
                        :disabled="true"
                        ><DxValidator ref="refValidateGiaVe">
                            <DxRequiredRule
                                message="Vui lòng nhập số tiền cần thu!" /></DxValidator
                    ></DxNumberBox>
                    <div class="label">Giá vé</div>
                </div>
            </div>
            <div v-else class="label-box pa">
                <DxTextBox
                    id="txtChoDaChon"
                    v-model="choDaChon"
                    styling-mode="outlined"
                    height="84px"
                    mode="text"
                    :disabled="true"
                />
                <div class="label">Chỗ đã chọn</div>
            </div>
            <div class="label-box pb-2">
                <DxTextArea
                    v-model="model.ghiChu"
                    id="txtGhiChu"
                    class="kichthuoclon"
                    :height="170"
                    validationMessageMode="always"
                    styling-mode="outlined"
                    :max-length="512"
                />
                <div class="label">Thông tin muốn nhắn nhủ nhà xe</div>
            </div>
            <DanhSachChipVue
                class="pa"
                :data="getDanhSachThongDiep"
                @ChipDangChon="
                    (e) => {
                        model.ghiChu = e.active ? e.text : '';
                    }
                "
            />
            <!-- Chọn hình thức nhận vé -->
            <div class="font-32 text-xs-left font-bold pb-4">
                Hình thức nhận vé
            </div>
            <div class="row justify-space-between align-center pb-5">
                <DxCheckBox
                    id="checkbox-layvegiay"
                    class="checkbox-nhanthongtinve"
                    v-model="model.layVeGiay"
                    text="Lấy vé giấy"
                    :disabled="true"
                />
                <DxCheckBox
                    id="checkbox-nhanvequasdt"
                    class="checkbox-nhanthongtinve"
                    v-model="model.nhanVeQuaSdt"
                    text="Gửi thông tin vé về số điện thoại"
                    :disabled="true"
                />
                <DxCheckBox
                    id="checkbox-nhanvequaungdung"
                    class="checkbox-nhanthongtinve"
                    v-model="model.nhanVeQuaUngDung"
                    text="Nhận vé qua ứng dụng"
                    :disabled="true"
                />
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import DanhSachChipVue from "../DanhSachChip";
import LuaChonDiemXuongVue from "../LuaChonDiemXuong";

import {
    DxButton,
    DxTextBox,
    DxTextArea,
    DxNumberBox,
    DxValidationGroup,
    DxValidator,
    DxCheckBox,
} from "devextreme-vue";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxNumericRule,
    DxPatternRule,
} from "devextreme-vue/data-grid";
export default {
    props: {
        DanhSachChoDaChon: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        DxButton,
        DxTextBox,
        DxTextArea,
        DxNumberBox,
        LuaChonDiemXuongVue,
        DanhSachChipVue,
        DxValidationGroup,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxCheckBox,
    },
    data() {
        return {
            phonePattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
            chonThanhToan: false,
            disabledThanhToan: true,
            xeHetCho: false,
            xeKhongCoSoDoCho: false,
            timeoutSoDienThoai: 0,
            isValidSdt: true,
            model: {
                tongTien: 0,
                soCho: 1,
                soDienThoai: "",
                ghiChu: "",
                choDaChon: "",
                layVeGiay: true,
                nhanVeQuaSdt: false,
                nhanVeQuaUngDung: false,
            },
        };
    },
    computed: {
        ...mapGetters("soDoCho", [
            "getDanhSachChoDaChon",
            "getDanhSachChoDaChonLocal",
        ]),
        ...mapGetters("thongTinThanhToan", ["getDanhSachThongDiep","getDanhSachSoChoPhu"]),
        ...mapState("thongTinThanhToan", {
            giaVeTheoDiemXuong: (state) => state.model.giaVeTheoDiemXuong,
            soChoMuaGhePhu: (state) => state.model.soChoMuaGhePhu,
        }),
        disableThanhToan() {
            if (this.model.ViDienTu) {
                return false;
            }
            return true;
        },
        choDaChon() {
            if (this.getDanhSachChoDaChonLocal) {
                return this.getDanhSachChoDaChonLocal
                    .map((e) => `${e.tenCho} (${$MoneyFormat(e.giaTien)}đ)`)
                    .join(", ");
            }
            return "";
        },
        soChoPhu: {
            get() {
                return this.soChoMuaGhePhu;
            },
            set(value) {
                this.setSoChoMuaGhePhu(value);
            },
        },
        giaVeDiemXuong: {
            get() {
                return this.giaVeTheoDiemXuong;
            },
            set(value) {
                this.setGiaVeTheoDiemXuong(value);
            },
        },
    },
    methods: {
        ...mapMutations("thongTinThanhToan", [
            "setSoDienThoai",
            "setTongTienTheoChoPhu",
            "setSoChoMuaGhePhu",
            "setGiaVeTheoDiemXuong",
            "setGoiYDanhSachSoChoPhu",
        ]),
        ...mapActions("thongTinThanhToan", ["layDanhSachThongDiep"]),
        onInitializedSoDienThoai(e) {
            setTimeout(() => {
                e.component.focus();
            }, 1000);
        },
        onFocusOutSoDienThoai() {
            //Ẩn ghi chú dưới trường email
            let IsValidSdt =
                this.$refs.refValidateSoDienThoai.instance.validate();
            this.isValidSdt = IsValidSdt.isValid;
        },
        onKeyUpSoDienThoai(e) {
            this.timeoutSoDienThoai = setTimeout(() => {
                clearTimeout(this.timeoutSoDienThoai);
                if (this.timeoutSoDienThoai) {
                    this.setSoDienThoai(e.event.srcElement.value);
                }
            }, 1000);
        },
        chonVi(item, list) {
            this.model.ViDienTu = item;
        },
        chonGoiYSoCho(item){
            this.soChoPhu= parseInt(item.text)
        }
    },
    mounted() {
        if (this.$route.query) {
            this.xeHetCho =
                this.$route.query.xeHetCho == "true"
                    ? true
                    : this.$route.query.xeHetCho == "false"
                    ? false
                    : null;
            this.xeKhongCoSoDoCho =
                this.$route.query.xeKhongCoSoDoCho == "true"
                    ? true
                    : this.$route.query.xeKhongCoSoDoCho == "false"
                    ? false
                    : null;
        }
        this.layDanhSachThongDiep();
        this.setGoiYDanhSachSoChoPhu(10)
    },
};
</script>
<style scoped>
.mota-style {
    font-size: 12px;
    font-style: italic;
    opacity: 80%;
    padding-left: 8px;
    padding-top: 8px;
}
.pa {
    margin-bottom: 32px;
}
.label-box .label {
    width: unset;
}
.label-box .label,
::v-deep.label-box input,
::v-deep.label-box textarea,
::v-deep.label-box .dx-placeholder {
    font-size: 28px !important;
}
.label-box .dx-textbox + .label {
    top: 26px;
}
.label-box .with-placholder.dx-numberbox.dx-editor-outlined + .label,
.label-box .dx-numberbox.dx-editor-outlined:not(.dx-texteditor-empty) + .label,
.label-box .with-placholder.dx-textbox.dx-editor-outlined + .label,
.label-box .dx-textbox.dx-editor-outlined:not(.dx-texteditor-empty) + .label {
    top: -18px;
}
.label-box .dx-textbox.dx-editor-outlined.dx-state-focused + .label {
    top: -18px;
}
::v-deep.label-box .dx-invalid-message > .dx-overlay-content {
    font-size: 16px;
}
::v-deep.checkbox-nhanthongtinve span {
    font-size: 24px;
}
::v-deep.checkbox-nhanthongtinve .dx-checkbox-icon {
    width: 24px;
    height: 24px;
}
</style>